.center_component {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    margin: 0;
}

.ant-spin-dot-item {
    background-color: #ff5a3c !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ff5a3c !important;
  border-right-width: 1px;
}

.ant-select:hover .ant-select-selector {
  border-color: #ff5a3c !important;
  border-right-width: 1px;
}



.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 10px 11px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 60px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 900;
  background-color: "transparent" !important;
}

::selection {
  color: #fff;
  background: "transparent" !important;
}